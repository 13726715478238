.signup-container1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.signup-max-width {
  align-self: center;
}
.signup-content1 {
  width: 100%;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.signup-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.signup-content3-google {
  margin: 0 -20px -100px;
  width: calc(100% + 40px);
}
.signup-content3 {
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.signup-text18 {
  display: inline-block;
}
.signup-text19 {
  display: inline-block;
}
.signup-input1 {
  width: 100%;
  max-width: 400px;
  padding: 10px 16px;
  border-radius: 20px;
  margin: 1px;
}
.signup-input1:focus {
  outline: var(--dl-color-theme-neutral-dark) auto 1px;
}
.signup-input1.input-incorrect:not(:focus) {
  outline: #ff0000dd auto 1px;
}
.signup-input-error {
  color: red;
  position: absolute;
  font-size: 0.8rem;
  top: 44px;
}
.form-success {
  margin-top: 30px;
  gap: 16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.form-success > p:first-child {
  font-weight: bold;
}
#googleFormIframe {
  height: 500px;
}
#signup {
  padding-bottom: 0;
}

@media(max-width: 991px) {
  .signup-content1 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .signup-content3 {
    position: relative;
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .signup-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .form-success {
    position: relative;
  }
  .signup-content3-google {
    margin-bottom: -20px;
  }
}
@media(max-width: 640px) {
  #googleFormIframe {
    height: 580px;
  }
}
